<template>
  <div class="college-specialty">
    <div class="nav">
      <div class="title">开设专业</div>
      <div class="nav-item">
        <span :class="{ nav: nav == 1 }" @click="getNav(1)">专科</span>
        <span :class="{ nav: nav == 0 }" @click="getNav(0)">本科</span>
        <span :class="{ nav: nav == 2 }" @click="getNav(2)">硕士</span>
        <span :class="{ nav: nav == 3 }" @click="getNav(3)">博士</span>
        <el-select v-model="category" size="mini" class="round_select" placeholder="请选择" @change="onChange">
          <el-option label="全部类别" :value="null" />
          <el-option v-for="item in groupList" :key="item.category" :label="item.category" :value="item.category" />
        </el-select>
      </div>
    </div>
    <div class="table">
      <el-table :header-cell-style="{ 'color': '#A8A8A8', 'letter-spacing': '1px' }"
        :cell-style="{ 'color': 'black', height: '57px', padding: '4px 0' }" :data="tableData"
        style="width: 100%;font-size:16px;text-align:center">
        <el-table-column prop="specialty_name" show-overflow-tooltip label="专业名称">
          <template slot-scope="scope">
            <p>{{ scope.row.specialty_name }}</p>
            <span v-if="scope.row.country_feature == 1"
              style="font-size: 12px;color: #4870F6; background-color: #F3F8FF;padding: 2px 0px;margin-right:15px;border-radius: 3px;">
              国家特色专业
            </span>
            <span v-if="scope.row.guojia == 1"
              style="font-size: 12px;color: #4870F6; background-color: #F3F8FF;padding: 2px 0px;margin-right:15px;border-radius: 3px;">
              国家级一流专业
            </span>

            <span v-if="scope.row.sheng == 1 && scope.row.guojia != 1"
              style="font-size: 12px;color: #4870F6; background-color: #F3F8FF;padding: 2px 0px;margin-right:15px;border-radius: 3px;">
              省级一流专业
            </span>

          </template>
        </el-table-column>
        <el-table-column prop="categories" show-overflow-tooltip width="120" label="学科门类" align="center" />
        <el-table-column prop="category" show-overflow-tooltip width="150" label="专业类别" align="center" />
        <el-table-column prop="xuewei" v-if="nav == 2" show-overflow-tooltip width="150" label="学位类型" align="center" />
        <el-table-column prop="study_year" v-if="nav < 2" show-overflow-tooltip width="80" label="学制" align="center" />
      </el-table>
    </div>
    <div class="page-pagination">
      <div class="pagination">
        <el-pagination :hide-on-single-page="true" :page-size="page.pageSize" :current-page="page.pageNum"
          :pager-count="11" :total="page.total" class="page-box" layout="prev, pager, next" prev-text='上一页'
          next-text='下一页' @current-change="pageChange" />
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: '',
  data() {
    return {
      category: null,
      groupList: [

      ],
      page: {
        pageSize: 8,
        pageNum: 1,
        total: 0,
      },
      tableData: [],
      id: null,
      nav: 0
    };
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.id = this.$route.query.cid;
    this.nav = this.$route.query.degree;

    // 获取批次/科类列表
    this.getData();
    this.getGroup()
  },
  methods: {
    getNav(e) {
      this.nav = e
      this.page.pageNum = 1
      this.category = null
      this.getGroup()
      this.getData()
    },
    getData() {
      if (!this.id) {
        return false
      }

      this.$fecth
        .post("school/getSchoolSpecialty", {
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,
          degree: this.nav,
          category: this.category,
          school_id: this.id,
        })
        .then((res) => {
          // console.log(res)
          this.tableData = res.list;
          this.page.total = res.count;
        });

    },
    getGroup() {
      if (!this.id) {
        return false
      }

      this.$fecth
        .get("school/getSchoolSpecialtyGroup", {
          params: {
            degree: this.nav,
            id: this.id,
          },
        })
        .then((res) => {
          this.groupList = res.list
        });

    },

    onChange() {
      this.page.pageNum = 1
      this.getData()
    },

    pageChange(page) {
      this.page.pageNum = page
      this.getData()
    },
  },
};
</script>

<style scoped lang='less'>
.college-specialty {
  position: relative;

  .title {
    font-size: 22px;
    display: inline-block;
    margin-right: 100px;
  }

  .nav-item {
    font-size: 20px;
    display: inline-block;

    span {
      border: 1px solid #4591F6;
      margin-right: 10px;
      border-radius: 5px;
      color: #4591F6;
      padding: 2px 7px;
    }

    .nav {
      background: #4591F6;
      color: white;
    }
  }

  .table {
    margin-top: 20px;
    height: 590px;
    padding-right: 50px;
  }

  .page-pagination {
    position: absolute;
    bottom: 0;
    width: 100%;

    .pagination {
      padding: 40px 0;

      .page-box {
        text-align: center;

        .el-pager li.active {
          background: red;
        }
      }
    }
  }


}
</style>

<style lang="less">
.college-specialty {
  .round_select {
    position: relative;
    top: -2px;
    width: 140px;
    margin-left: 120px;

    .el-input--mini .el-input__inner {
      background: #F8F8F8;
      border: 0;
      font-size: 16px;
      color: black;
    }

    .el-select .el-input .el-select__caret {
      color: black;
    }
  }

  .page-box {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .el-pager li {
      border-radius: 30px;
      margin-right: 8px;
      min-width: 20px;
      height: 20px;
      line-height: 20px;
      list-style-type: none
    }

    .el-pager li.active {
      background: #4591F6;
      color: white;
    }
  }
}
</style>
